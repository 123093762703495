
import './App.scss';
import { Dapp } from './multiverse.js';
import React, {useState, useEffect} from 'react';
import Web3 from 'web3';

import {
  Link,
  Outlet
} from "react-router-dom";
import { chainMap, enforceChain } from './tools/ChainTools.js'
import detectEthereumProvider from '@metamask/detect-provider';
import { Token } from './tools/token.js'

const logo = require('./gdeli/MOONBASE.png');

export function App() {

    const [isPending, setIsPending] = useState(false)
	const [connected, setConnected] = useState(false)
	const [mmBtnText, setMMBtnText] = useState("Connect")
	const [accounts, setAccounts] = React.useState([]);
	const [currentAccount, setCurrentAccount] = useState("")

	

	// attached to the accountsChanged event listener
	// triggered once manually via connectMM
	function handleNewAccounts(newAccounts) {
		setCurrentAccount(newAccounts);
	}

	// attached to the chainChanged event listener
	function handleChainChange(chainId) {
		enforceChain("Base", ()=>{})
	}

	// when triggered, connectMM requests the user connects to the dApp
	// if the user is already connected, or after the user connects,
	// connectMM sets the accounts state to the user's connected accounts,
	// and sets the connected state to true
	const connectMM = () => {
	    window.ethereum
	      .request({ method: 'eth_requestAccounts' })
	      .then(handleAccountsChanged)  
	      .catch((err) => {
			    console.error(err)
		  	})
	}

	const handleAccountsChanged = (accs) => {
	  if (accs.length === 0) {
	    // MetaMask is locked or the user has not connected any accounts
	    console.log('Please connect to MetaMask.');
	  } else if (accs[0] !== currentAccount) {
	    // currentAccount = accs[0];
	    // Do any other work!
	    setCurrentAccount(accs[0])
	    setConnected(true)
	    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId))
	  }
	}

	useEffect(()=>{
		const provider = detectEthereumProvider()
		if (provider) {
		  window.ethereum
		  	.request({method: 'eth_accounts'})
		  	.then(handleAccountsChanged)
		  	.catch((err) => {
		  		// Some unexpected error.
			    // For backwards compatibility reasons, if no accounts are available,
			    // eth_accounts will return an empty array.
			    console.error(err)
		  	})
		} else {
		  console.log('Please install MetaMask!');
		}


	})


  // once the user is connected, add the accountsChanged event listener
  // and add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChange);
      
      
      return () => {
        window.ethereum.on('accountsChanged', handleAccountsChanged);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);

  return (
    <div className="App">
    
      <div className="App__Body">
      	<Outlet />
      </div>
      <div className="logo">
      	<img src={logo.default} />
      </div>
       <button onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>

      <div className="App__Nav">
  			<Link to="about">about</Link>
      		<Link to="biodomes">biodomes</Link>
      		<Link to="galaxy">galaxy</Link>
      		<Link to="captains">captains</Link>
      	</div>
    </div>
  );
}


const parseDec18 = (x) => {
		let strX = x.toString()
		let right = "0"
		let left = "0"
		if (strX.length < 18) {
			right = "0".repeat(18 - strX.length) + strX
			left = "0"
		} else {
			right = strX.substring(strX.length - 18)
			left = strX.substring(0,strX.length - 18)
		}
		
		return left + "." + right
	}

export function About() {
	return(
		<div className="Lander">
	    	
    		<h1>About</h1>
    		<p>Moonbase is an onchain Space Exploration universe.</p>
    		<h2>Moonbase</h2>
    		<p>The $MOONBASE token is <a href="https://basescan.org/token/0xd4306991f8a5CEF2d2bCB3262224dEd11BD18baD" target="_blank">0xd4306991f8a5CEF2d2bCB3262224dEd11BD18baD</a>. Trading will be supported exclusively on velocimeter. It has a supply of Maximum Supply of 69,420.</p>
    		<p>You can also mint Moonbase Biodomes.</p>
    		<p>Each Biodome can be used to colonize planets, colonies must be funded with at least 1 $MOONBASE.</p>
    		<p>Biodomes #0-1336 cost 0.0001 ETH, #1337-4241 cost 0.001 ETH, #4242+ cost 0.01 ETH</p>
    		<p>Each Biodome stat rolls between 0-6</p>

	   
	    	

	    </div>
		);
}

export function Lander() {
	return (
    <div className="Lander">
    	<div className="Lander__Focus">
    		<h1>Moonbase</h1> 
    	</div>

    </div>
  );

}