import React from 'react';
import ReactDOM from 'react-dom';
import { App,  Lander, About } from './App';
import { Dapp, Galaxy, Captains } from './multiverse.js';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";



ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
      	<Route index element={<Lander />} />
      	<Route path="about" element={<About />} />
        <Route path="biodomes" element={<Dapp />} />
        <Route path="galaxy" element={<Galaxy />} />
        <Route path="captains" element={<Captains />} />
        {
        	// <Route path="cosmogonies" element={<Cosms />} />
        }
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)



