import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'
import { truths } from './deliJS/farmgod-core.js'
import { Token } from './tools/token.js'
import {formatTokenBalance} from './tools/tokenUtilities'


export function Galaxy() {
  const [id, setId] = React.useState(0);
  const [orbits, setOrbits] = React.useState(0);
  const [orbitArr, setOrbitArr] = React.useState([]);
  const [planetType, setPlanetType] = React.useState("");
  const [moonType, setMoonType] = React.useState("");
  const [system, setSystem] = React.useState(0);
  const [planetState, setPlanetState] = React.useState(0);
  const [moonState, setMoonState] = React.useState(0);
  const [suborbits, setSuborbits] = React.useState(0);
  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');
  var galaxy = new web3.eth.Contract(truths["galaxy"]["abi"], truths["galaxy"]["address"])


  const getSystemInfo = () => {

    galaxy.methods.orbits(solarSystem.current.value).call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setOrbits(res)
        setSystem(solarSystem.current.value)
        setStepZero(false)
        setStepOne(true)
      })
  }

  const getPlanetInfo = () => {
    return () => {

      galaxy.methods.planetType(system,planet.current.value).call({from: window.ethereum.selectedAddress})
        .then((res)=>{
          setPlanetType(res)
          setPlanetState(planet.current.value);
          galaxy.methods.suborbits(system, planet.current.value)
            .call({from: window.ethereum.selectedAddress})
            .then((res)=>{
                setSuborbits(res);
                setStepOne(false);
                setStepTwo(true);
            })
        })
    }
  }

  const getMoonInfo = () => {
    return () => {
      galaxy.methods.moonType(system,planet.current.value,moon.current.value)
        .call({from: window.ethereum.selectedAddress})
        .then((res)=>{
          setMoonType(res)
          setMoonState(moon.current.value)
          setStepTwo(false)
          setStepThree(true)
        })
    }
  }

  React.useEffect(()=>{
    console.log(orbits)
    if (orbits > 0) {
      let x = new Array(Number(orbits))
      console.log(x)
      x.fill(0)
      console.log(x)
      setOrbitArr(x)
    }
    
  },[orbits])

  const solarSystem = React.useRef();
  const planet = React.useRef();
  const moon = React.useRef();

  const [stepZero, setStepZero] = React.useState(true);
  const [stepOne, setStepOne] = React.useState(false);
  const [stepTwo, setStepTwo] = React.useState(false);
  const [stepThree, setStepThree] = React.useState(false);

  const backToStepTwo = () => {
    setStepThree(false)
    setStepTwo(true)
  }

  const backToStepOne = () => {
    setStepTwo(false)
    setStepOne(true)
  }

  const backToStepZero = () => {
    setStepOne(false)
    setStepZero(true)
  }


  return (
    <div className="galaxy">
      <h2>Exploring the Galaxy</h2>
      <div className={"step step-active--" + stepZero}>
        <p>Welcome explorer. Begin by inputting a number. This will transport you to a solar system.</p>
        <div>
          <input ref={solarSystem}/>
          <button onClick={getSystemInfo}>Explore</button>
        </div>
      </div>

      <div className={"step step-active--" + stepOne}>
        <p>Welcome to Solar System #{system}</p>
        <p>This system has {orbits} planets</p>
        <p>Enter a number 0-{orbits-1}</p>
        <div>
          <input ref={planet}/>
          <button onClick={getPlanetInfo(planet)}>Explore Planet</button>
        </div>
        <p><button onClick={backToStepZero}>Return to Galaxy</button></p>
        <div className="orbit-visuals">
        {
          orbitArr.map( (o,i)=>(<div key={"o" + i} className={"orbit orbit--" + i}></div>))
        }
        </div>
      </div>

      <div className={"step step-active--" + stepTwo}>
        <p>You explore planet #{planetState} in system #{system}</p>
        <p>This {planetType} planet has {suborbits} moons</p>
        <p>Enter a number 0-{suborbits-1}</p>
         <div>
          <input ref={moon}/>
          <button onClick={getMoonInfo()}>Explore Moon</button>
          
        </div>
        <p><button onClick={backToStepOne}>Return to System</button></p>
        <div className="orbit-visuals">
          <div className={"planet planet--" + planetType}></div>
        </div>
      </div>

      <div className={"step step-active--" + stepThree}>
        <p>You explore moon #{moonState} around planet #{planetState} in system #{system}</p>
        <p>This is a {moonType} moon.</p>
        <div>
          <button onClick={backToStepTwo}>Return to Planet</button>
        </div>
        <div className="orbit-visuals">
          <div className={"planet planet--" + moonType}></div>
        </div>
      </div>
      
      
    </div>

    );
}


export function Dapp() {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
          .catch((error) => {

          })
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

var biodomes = new web3.eth.Contract(truths["biodome"]["abi"], truths["biodome"]["address"])

const Biodome = (props) => (<div className="alchemist"><img src={props.img} /></div>)
const [biodomeImg, setBiodomeImg] = React.useState("");
const [biodomeName, setBiodomeName] = React.useState("");
const [biodomeDesc, setBiodomeDesc] = React.useState("");

const [biodomeNum, setBiodomeNum] = React.useState(1)
const [biodomeOwner, setBiodomeOwner] = React.useState("")

const letItRip = () => {
  getBiodome(biodomeNum) 
}


const getBiodome = (i) => {
  var batch = new web3.BatchRequest();
  batch.add(biodomes.methods.tokenURI(i).call.request(
        {from: window.ethereum.selectedAddress},
      
        (err, res) => {
          var x = JSON.parse(atob(res.substring(29)))
          setBiodomeImg(x.image)  
        }))
  batch.add(biodomes.methods.ownerOf(i).call.request(
      {from: window.ethereum.selectedAddress},
      (err, res) => {
          if (err) {
            setBiodomeOwner (false)
          } else {
            setBiodomeOwner(res)  
          }
          
        }
    ))
  batch.execute();
}

React.useEffect(() => {

  getBiodome(biodomeNum)
}, [biodomeNum])

const mint = (id) => {
  if (id >= 0 && id < 1337) {
    biodomes.methods.create(id).send(
      {
        from: window.ethereum.selectedAddress, 
        value: web3.utils.toWei("0.0001","ether")
      })
      .then(
        (res)=> {
          getBiodome(biodomeNum)
        })
  } else if (id >= 1337 && id < 4242) {
    biodomes.methods.create(id).send(
      {
        from: window.ethereum.selectedAddress, 
        value: web3.utils.toWei("0.001","ether")
      })
      .then(
        (res)=> {
          getBiodome(biodomeNum)
        })
  
  } else {
    biodomes.methods.create(id).send(
      {
        from: window.ethereum.selectedAddress, 
        value: web3.utils.toWei("0.01","ether")
      })
      .then(
        (res)=> {
          getBiodome(biodomeNum)
        })
  }
  
}

const tokenId = React.useRef();

  return (
    <div className={"App-wrapper " + " connected--" + connected}>

   
      
      <h2>Moonbase Biodomes</h2>
      <div>
        <div className="checker">
          <input ref={tokenId} />
          <button onClick={() => {

          setBiodomeNum(tokenId.current.value) }}>Check</button>
        </div>
        <div className="dome-header">
          <h1>Biodome #{biodomeNum}</h1>
          <div>
          { 
            (!biodomeOwner) ? 
              <button onClick={() => {mint(biodomeNum)}}>
                {(biodomeNum >= 1337) ? ( (biodomeNum >= 4242) ? "Mint for 0.01 ETH" : "Mint for 0.001 ETH") : "Mint for 0.0001 ETH"}
              </button> : "Owned by: " + biodomeOwner}
          </div>
        </div>
        <Biodome img={biodomeImg}/>
        <div className="nav-btns">
          
          <button onClick={() => {
          let x = biodomeNum
          setBiodomeNum(--x) }}>Back</button>
          
          <button onClick={() => {
          let x = biodomeNum
          setBiodomeNum(++x) }}>Next</button>
          </div>
      </div>
    </div>

  );
}


export function Captains() {

  return (
    <div>
      <h1>Captains</h1>
    </div>
    );
}
