export const FarmgodCore = {

	

	"echo": {
		"name": "ECHO",
		"address": "0x54477a1d1bb8c1139eef754fd2efd4ddee7933dd",
		"icon": require("../gdeli/MOONBASE.png"),
		"decimals": 9,
		"scope": "interuniversal",
		"type": "-",
		"archetype": "reflective",
		"tax": "2%",
		"pools": [{"chef":"acremaker", "pid":2}],
		"max": "69420000",
		"staticimg":"https://maxus.blog/static/media/echo.60b77b4b.png"
	}

}





export const contractConfigs = {
	"generic": {
		"abi": require("../tools/GenericABI.json"),
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D"
	},
	"chef": {
		"abi": require("../deliJS/chefs/AcreMaker.json"),
		"address": "0x3c559c90fD1c7a73D6EaC8510B8Fb16Ca333226F"
	}
}



export const truths = {
	"biodome": {
		"abi": require("../biodome.json"),
		"address": "0x9FC64b084DBa291E4Ec2d4A9CFd08624E6644CE9"
	},
	"galaxy": {
		"abi": require("../galaxy.json"),
		"address": "0x31b1B0715932332fbC9c6CDB10829630f6AfBCB9"
	}


	 //0x9FC64b084DBa291E4Ec2d4A9CFd08624E6644CE9
	//0x3D6b277756fd4D3c11aA945Bb937156A76024395 test

	//0x55D4b5707d480066a6c65Fa384F28e3deE22F00b test

}


